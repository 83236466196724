
import { Options, Vue } from 'vue-class-component';
import Swal from 'sweetalert2'

import svr from '@/services/lists'

import Panel from './components/Panel.vue';
import Lists from './components/Lists.vue';
import Items from './components/Items.vue';

import { List, ListItem } from '@/types';

import { useStore, mapGetters, mapActions } from 'vuex';

import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

import { 
	getAuth, 
	onAuthStateChanged,
	EmailAuthProvider,
	signInWithCredential,
	createUserWithEmailAndPassword,
	signInWithEmailAndPassword,
	sendEmailVerification,
	signOut
} from 'firebase/auth'

@Options({
  components: {
		Panel,
		Lists,
		Items
  },
	computed: {
		...mapGetters(['exists', 'lastSync', 'device', 'loggedIn',
			'storageReady', 'syncRequired', 'idToken', 'onLine' ])
	},
	methods: {
		...mapActions([ 'updateDevice' ])
	},
	watch: {
		onLine: 'doSync',
		loggedIn: 'resetMenu',
		storageReady: 'updateDevice',
		errorMsgQ: { handler: 'clearError', deep: true },
	},

})

export default class App extends Vue {

	public store = useStore();
	public errorMsgQ = [] as string[];
	public errorMsg = "";
	public list = "";

	public auth = getAuth();

	public setup = { 
		signInFlow: 'popup', // 'redirect',
		// signInSuccessUrl: 'https://maximus.mindtunnel.com:8080',
		signInOptions: [
			{ 
				provider: EmailAuthProvider.PROVIDER_ID,
				requireDisplayName: true 
			},
			'google.com',
			'facebook.com',
			],
		callbacks: {
			uiShown: function() {
				//console.log(`uiShown!`);
				const l = document.getElementById('loader');
				if ( l ) {
					l.style.display = 'none';
				}
			},
			signInSuccessWithAuthResult: function(authResult, redirectUrl) {
				//console.log(`authResult: %o`,authResult);
				//console.log(`redirectUrl: %o`,redirectUrl);
				return true;
			},
		},
	};

	public async getIdToken(): Promise<string> {
		let idToken = '';
		if ( this.auth.currentUser ) {
			idToken = await this.auth.currentUser.getIdToken(true);
		}
		//console.log(`idToken: %o`,idToken);
		return idToken;
	}


	public async doSync(forcedList: List): Promise<void> {

		const popPending = Swal.mixin({
			title: 'mindtunnel lists',
			icon: 'question', 
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			confirmButtonText: 'Accept',
			confirmButtonColor: '#416c94',
			denyButtonText: 'Reject',
			denyButtonColor: '#416c94',
			showDenyButton: true,
			reverseButtons: true
		});

		const popDuplicate = Swal.mixin({
			title: 'mindtunnel lists',
			icon: 'error', 
			confirmButtonText: 'OK',
			confirmButtonColor: '#416c94',
		});

		if ( this.store.getters.loggedIn && this.store.getters.onLine ) {
			try {
				const mDate = Date.now();
				let syncList = [] as List[];
				if ( forcedList && forcedList.name ) {
					//forcedList.modified = 0;
					syncList = [ forcedList ];
				} else {
					syncList = this.store.getters.syncRequired;
				}

				const a = await svr.sync({
					idToken: await this.getIdToken(),
					lastSync: this.store.getters.lastSync,
					lists: syncList
				});

				//console.log(`doSync svr.sync returned: %o`,a);

				for ( let l of (a as any).lists ) {
					l.synced = mDate;
					l.items.forEach( (i) => {
						(i as ListItem).synced = mDate;
					});

					await this.store.dispatch('commitList', l);
				}

				if ( typeof((a as any).pendingShare).name != 'undefined' ) {
					const s = (a as any).pendingShare;
					const r = await popPending.fire({ html: 
						`<br>Do you want to accept shared list <b>${s.name}</b>?</br>`,
					});

					const me = this.store.getters.email;
					const sDate = Date.now();

					if ( r.isConfirmed || r.isDenied ) { 

						if ( r.isConfirmed && this.store.getters.exists(s.name) ) {
							await popDuplicate.fire({
									html: `<br>You already have a list named <b>${s.name}</b>.` +
												`<br>You will have to rename it before accepting ` +
												`this one.</br>`,
							});

						} else {
							const p = await svr.accept({ 
								idToken: await this.getIdToken(),
								lastSync: this.store.getters.lastSync,
								lists: [ s ],
								action: r.isConfirmed ? 'accept' : 'reject'
							});

							//console.log(`accept returned: %o`,p);
							const sDate = Date.now();

							for ( let l of (p as any).lists ) {
								l.synced = sDate;
								l.items.forEach( (i) => {
									(i as ListItem).synced = sDate;
								});
								await this.store.dispatch('commitList', l);
							}
						}
					}
				}
				this.store.dispatch('setLastSync', mDate);
			} catch (e) {
				this.showError(`doSync error: ${e.toString()}`);
			}
		} 
	}

	public showError(err: string): void {
		console.error(`showError: %o`,err);
		this.errorMsgQ.push(err.toString());
	}

	public clearError(val: string[], oldVal: string[]): void {
		if ( this.errorMsgQ.length > 0 ) {	
			this.errorMsg = this.errorMsgQ[0];
			setTimeout( () => { 
				this.errorMsg = '';
				this.errorMsgQ.splice(0, 1);
				if ( this.errorMsgQ.length > 1 ) {
					this.errorMsg = this.errorMsgQ[0];
				}
			}, 5000);
		} else {
			this.errorMsg = '';
		} 
	}


	public async selectList(list: List): Promise<void> {
		try {
			const mDate = Date.now();
			const lastSync = this.store.getters.lastSync;

			await this.doSync(list);
			/*
			const a = await svr.sync({ 
				idToken: await this.getIdToken(),
				lastSync: lastSync, 
				lists: [ list ] 
			});

			for ( let l of (a as any).lists ) {
				l.synced = mDate;
				l.items.forEach( (i) => {
					(i as ListItem).synced = mDate;
				});

				const r = await this.store.dispatch('commitList', l);
			}
			*/

			this.list = list.name;
		} catch (e) {
			this.showError(`selectList: ${e.toString()}`);
		}
	}


	get hasError(): boolean {
		const r = this.errorMsg.length > 0;
		return r;
	}


	public async initMenu(): Promise<void> {
		//console.log(`initMenu`);
		const a = this.auth;
		const ui = firebaseui.auth.AuthUI.getInstance() || 
			new firebaseui.auth.AuthUI(this.auth);

		//console.log(`auth: %o`,this.auth);
		try {
			//console.log(`pending? %o`,ui.isPendingRedirect() );
			if ( ! ui.isPendingRedirect() ) {
				await ui.reset();
			}

			await ui.start('#firebaseui-auth-container', this.setup);
			//console.log(`ui.start done`);

		} catch (e) {
			console.error(`initMenu: %o`,e);
			this.showError(`initMenu: ${e.toString()}`);
		}
	}

	public resetMenu(loggedIn): void {
		if ( ! loggedIn ) {
			setTimeout( () => {
				this.initMenu();
			}, 500);
		}
	}

	mounted() {
		//console.log(`mounted!`);

		setTimeout( () => {
			this.initMenu();
		}, 500);

		setTimeout( () => {
			if ( ! this.store.state.storageReady ) {
				this.store.dispatch('initialize');
				this.initMenu();
			}
		}, 2000);
	}
}
