

import { Options, Vue } from 'vue-class-component';
import { useStore, mapGetters } from 'vuex';

import svr from '../services/lists';
import { getAuth } from 'firebase/auth';
import Swal from 'sweetalert2'

import { List } from '@/types'

@Options({
	props: [ 'thisList', 'key' ], 

	components: {},

	computed: {
		...mapGetters( [ 'loggedIn', 'email', 'isShared', 'onLine' ] ),
	},

	emits: [ 'error', 'share', 'select', 'rename', 'delete', 'check' ],
})

export default class ListsDetails extends Vue {

	private store = useStore();
	public auth = getAuth();
	private editName = false 
	private newName = '' 
	private showShare = false
	private shareList = '' 
	private shareText = 'Enter emails, ' +
											'separated by commas or white space, to share this ' +
											'list with. ' +
											'\nTo stop sharing, hit clear and ' +
											'then submit.'
	private taHeight =  '48px'

	public grow(element) {
		element.target.style.height = "32px";
		element.target.style.height = (element.target.scrollHeight)+8+"px";
		this.taHeight = element.target.style.height;
	}

	public async getIdToken(): Promise<string> {
		let idToken = '';
		if ( this.auth.currentUser ) {
			idToken = await this.auth.currentUser.getIdToken(true);
		}
		//console.log(`idToken: %o`,idToken);
		return idToken;
	}


	public async showShareInit(list: List): Promise<void> {
		try {
			if ( !this.showShare ) {
				const users = await svr.getMembers({
					idToken: await this.getIdToken(),
					list: list
				});
				//console.log(`getMembers users: %o`,users);
				if ( users ) {
					this.shareList = users.join('\n').trim();
				} else {
					this.shareList = '';
				}
				this.showShare = true;
			} else {
				this.showShare = false;
			}
		} catch (e) {
			this.$emit('error', `showShareInit: ${e.toString()}`);
		}
	}


	public addShare() {
		const me = this.store.getters.email;
		const reg = new RegExp(`^${me}$`,'i');

		const emailRegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
		const email = RegExp(emailRegExp);
		let sl = this.shareList.toLowerCase().trim().split(/[ ,$\n]+/);


		let bad = [] as string[];
		let dupes = [] as string[];
		sl.forEach( (em) => {
			if ( em.length > 0 && ! email.test(em) ) {
				bad.push(em);
			}
		});

		bad.forEach( (b) => {
			const idx = sl.findIndex( x => x == b);
			if ( idx != -1 ) {
				this.$emit('error',`Removing invalid email [${b}]`);
				sl.splice(idx,1);
			}
		});

		// filter out duplicates
		sl = sl.filter((v, i, a) => a.indexOf(v) === i); // .join('\n');

		sl = sl.filter(e => !reg.test(e));

		if ( sl.length == 1 && sl[0].length == 0 ) {
			sl = [];
			this.shareList = '';
		} 

		this.showShare = false;
		this.$emit('share', sl);
	}

	public renameList(list: List): void {
		if ( list.shared ) {
			this.$emit('error', 'You may not rename shared lists!');
			this.editName = false;
			this.newName = list.name;
		} else {
			const oldName = list.name;
			if ( this.editName ) {
				this.editName = false;
				this.newName = this.newName.trim();
				if ( this.newName.length > 0 && oldName != this.newName ) {
					this.$emit('rename', this.newName );
				}
			} else {
				this.editName = true;
				this.newName = oldName;
			}
		}
	}


	public clearAction(name: string): void {
		const q = `Really delete <b>${name}</b>?`;
		Swal.fire({ 
			title: 'mindtunnel lists',
			icon: 'warning', 
			html: q, 
			confirmButtonText: 'Delete',
			confirmButtonColor: '#416c94',
			showCancelButton: true,
			reverseButtons: true 
		}).then( (r) => {
			if ( r.isConfirmed ) {
				this.$emit('delete', name);
			}
		});
	}

	get actionIcon(): string {
		return this.editName ? 'save_alt' : 'edit';
	}
}
