import { List, ListItem } from '@/types'

import svr from '@/services/lists'
import { v4 as uuid } from 'uuid';

/**
 * return the index in the array of the item with the requested id
 * throws error if name not found or if multiple matches
 **/
const findById = (lists, id) => {

	if ( typeof(id) === "undefined" || id.length < 1 ) {
			throw 'Invalid Id';
	}

  const idi = lists.findIndex(x => x._id == id);
  if ( idi == -1 ) {
    throw `findById: id [${id}] not found`;
  } else {
    return idi;
  }
};

/**
 * return the index in the array of the item with the requested name
 * throws error if name not found or if multiple matches
 **/
const findByName = (lists, name) => {

	if ( typeof(name) === "undefined" || name.length < 1 ) {
			throw 'Invalid Name';
	}

	const newName = name.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, "\\$&");
	//console.log(`name: ${name}, newName: ${newName}`);
	const rel = new RegExp(`^${newName}$`,'i');
	const hits = lists.filter(x => rel.test(x.name));
	if ( hits.length > 1 ) {
		//console.log('hits: ', hits);
		/*
		for ( let i=0; i<hits.length-1; i++ ) {
			const n = `${hits[i].name} ${uuid()}`;
			console.log(`rename ${i}:${hits[i].name} -> ${n}`);
			hits[i].name = n;
			hits[i].modified = Date.now();
		}
		*/
		//const hits1 = lists.filter(x => rel.test(x.name));
		//if ( hits1.length > 1 ) {
		if ( hits.length > 1 ) {
			//console.log('hits1: ', hits);
			throw 'Multiple Names';
		} else {
			return lists.findIndex(x => rel.test(x.name));
		}
	} else if ( hits.length == 0 ) {
		throw 'Name not Found';
	} else {
		return lists.findIndex(x => rel.test(x.name));
	}
};

// return true if the name is ok for a new list
const checkName = (lists, name): boolean => {
	if ( typeof(name) === "undefined" || name.length < 1 ) {
			throw 'Invalid Name';
	}
	const rel = new RegExp(`^${name}$`,'i');
	const idx = lists.findIndex(x => rel.test(x.name));
	return idx == -1
};


const checkListID = (storeLists: List[], serverList: List): void => {
	const res = new RegExp(`^${serverList.name}$`, 'i');

	const hits = storeLists.filter(x => 
		res.test(x.name) ||
		(x._id == serverList._id) );
	
	if ( hits.length > 1 ) {
		throw `checkListID: ${serverList._id} / ${serverList.name} matched ${hits.length} lists!`
	} 
};

const checkUniqueList = (storeLists: List[], newList: List): void => {
	if ( newList._id != '' ) {
		const ids = storeLists.filter(x => x._id == newList._id);
		if ( ids.length != 0 ) {
			throw `checkUniqueList: ID ${newList._id} already exists!`;
		}
	}
	const names = storeLists.filter(x => x._id == newList.name);
	if ( names.length != 0 ) {
		throw `checkUniqueList: Name ${newList.name} already exists!`;
	}
};

const checkItemID = (storeItems: ListItem[], serverItem: ListItem): void => {
  const newName = serverItem.name.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, "\\$&");
	//const res = new RegExp(`^${serverItem.name}$`, 'i');
	const res = new RegExp(`^${newName}$`, 'i');

	const hits = storeItems.filter(x => 
		res.test(x.name) ||
		(x._id == serverItem._id) );
	
	if ( hits.length > 1 ) {
		throw `checkItemID: ${serverItem._id} / ${serverItem.name} matched ${hits.length} items!`
	} 
};

const checkUniqueItem = (storeItems: ListItem[], newItem: ListItem): void => {
	if ( newItem._id != '' ) {
		const ids = storeItems.filter(x => x._id == newItem._id);
		if ( ids.length != 0 ) {
			throw `checkUniqueItem: ID ${newItem._id} already exists!`;
		}
	}
	const names = storeItems.filter(x => x._id == newItem.name);
	if ( names.length != 0 ) {
		throw `checkUniqueItem: Name ${newItem.name} already exists!`;
	}
};

const modKeys = ['name', 'checked', 'created', 'modified', 
	'synced', 'deleted', 'shared' ];

const state = () => ({
	lastSync: 0,
	lists: [] as List[],
})


const getters = {
	lastSync(state) {
		return state.lastSync;
	},

	isShared: (state) => (name) => {
		return state.list.filter(l => l.name == name).shared;
	},

	exists: (state) => (name) =>	{
		const reg = new RegExp(`^${name}$`,'i');
		const idx = state.lists.findIndex(x => reg.test(x.name));
		return idx == -1 ? false : true;
	},

	lists(state) {
		return state.lists;
	},

	syncRequired(state) {
		const sl = [] as List[];
		//console.log(`syncRequired: lastSync: ${state.lastSync}`);
		//console.log(`syncRequired: lists: %o`,state.lists);

		state.lists.forEach( (l) => {
			if ( state.lastSync == 0 || l._id == '' || l.synced < l.modified ) {
				const c = Object.assign({}, l);
				delete c.synced;
				c.items = [];
				//c._id = state.lastSync == 0 ? '' : c._id;
				l.items.forEach( (i) => {
					if ( state.lastSync == 0 || i._id == '' || i.synced < i.modified ) {
						const d = Object.assign({}, i);
						delete d.synced;
						//d._id = state.lastSync == 0 ? '' : d._id;
						c.items.push(d);
					}
				});
				sl.push(c);
			}
		});

		//console.log(`syncRequired: toSync: %o`,sl);

		return sl;
	}
}

const mutations = {

	// mark a list as deleted. 
	// if it hasn't been synced, fully delete it.
	deleteList(state, mod: { listId: string, listName: string } ) {
		const mDate = Date.now();
    //console.dir()
    let idl = -1;
    //console.log(`search: ${mod.listId}:${mod.listName}`);
    if ( mod.listId == '' ) {
      idl = findByName(state.lists, mod.listName);
    } else {
      idl = findById(state.lists, mod.listId);
    }
		//const idl = findByName(state.lists, mod.listName);
    //
    //console.log(`idl: ${idl}`);
		const list = state.lists[idl];

    //console.dir('')
		if ( list._id == '' ) {
			state.lists.splice(idl, 1);
		} else {
			if ( list.shared ) {
				throw `You must unshare list [${list.name}] before deleting it!`;
			} else {
				list.deleted = true;
				list.modified = mDate;
				state.lists.splice(idl, 1, list);
			}
		}
	},

	// mark an item as deleted. 
	// if it hasn't been synced, fully delete it.
	deleteItem(state, mod: { listName: string, itemId: string, itemName: string }): void {
		const mDate = Date.now();
		
		const idl = findByName(state.lists, mod.listName);
		const list = state.lists[idl];
    //console.error(`deleteItem: list ${list.name}`);

		//const idi = findByName(list.items, mod.itemName);
    let idi = -1;
    //console.error(`search: [${mod.itemId}]:[${mod.itemName}]`)
    if ( mod.itemId == '' ) {
      idi = findByName(list.items, mod.itemName);
    } else {
      idi = findById(list.items, mod.itemId);
    }

    //console.error(`requested: [${mod.itemId}]:[${mod.itemName}]`);
    //console.error(`deleting [${list.items[idi]._id}]:[${list.items[idi].name}]`);

		const item = list.items[idi];
		if ( item._id == '' ) {
			list.items.splice(idi,1);
			list.modified = mDate;
		} else {
			item.deleted = true;
			item.modified = mDate;
			list.items.splice(idi,1,item);
			list.modified = mDate;
		}
    //console.log('final:', JSON.stringify(state.lists[idl]));
		state.lists.splice(idl, 1, list);
	},


	renameList(state, mod: { oldName: string, newName: string } ): void {
		const mDate = Date.now();

		const ido = findByName(state.lists, mod.oldName);
		const list = state.lists[ido];
		if ( checkName(state.lists, mod.newName) ) {
			list.name = mod.newName
			list.modified = mDate;
			state.lists.splice(ido, 1, list);
		} else {
			const idn = findByName(state.lists, mod.newName);
			const del = state.lists[idn].deleted ? '(deleted)' : '';
			throw `renameList: ${mod.newName} ${del} already exists!`;
		}
	},


	renameItem(state, mod: {listName: string, oldName: string, newName: string }): void {
		const mDate = Date.now();

		const idl = findByName(state.lists, mod.listName);
		const list = state.lists[idl];

		const ido = findByName(list.items, mod.oldName);
		const item = list.items[ido];

		if ( checkName(list.items, mod.newName) ) {
			item.name = mod.newName;
			item.modified = mDate;
			list.items.splice(ido, 1, item);
			list.modified = mDate;
			state.lists.splice(idl,1,list);
		} else {
			const idn = findByName(list.items, mod.newName);
			const del = list.items[idn].deleted ? '(deleted)' : '';
			throw `renameItem: ${mod.newName} ${del} already exists!`;
		}
	},


	checkList(state, name: string): void {
		const mDate = Date.now();

		const idl = findByName(state.lists, name);
		const list = state.lists[idl];
		list.checked = ! list.checked;
		list.modified = mDate;
		state.lists.splice(idl, 1, list);
	},



	checkItem(state, mod: { listName: string, itemName: string }): void {
		const mDate = Date.now();

		const idl = findByName(state.lists, mod.listName);

		const list = state.lists[idl];
		const idi = findByName(list.items, mod.itemName);
		list.items[idi].checked = !list.items[idi].checked;
		list.items[idi].modified = mDate;
		list.modified = mDate;
		state.lists.splice(idl,1,list);
	},

	
	addList (state, name: string): void { 
		const mDate = Date.now();

		if ( checkName(state.lists, name) ) {
			const list = { 
				_id: '',
				name: name.trim(), 
				checked: false,
				created: mDate,
				modified: mDate,
				synced: 0,
				deleted: false,
				shared: false,
				items: [] as ListItem[],
			};
			state.lists.push(list);
		} else {
			const idd = findByName(state.lists, name);
			const list = state.lists[idd];

			if ( list.deleted || list.checked ) {
				list.checked = false;
				list.deleted = false;
				list.modified = mDate;
				state.lists.splice(idd, 1, list);
			} else {
				throw `addList: ${name} already exists!`;
			}
		}
	},


	addItem(state, mod: { listName: string, itemName: string }): void {
		const mDate = Date.now();
		const rel = new RegExp(`^${mod.listName}$`,'i');

		const newName = mod.itemName.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, "\\$&");

		//console.error(`add [${mod.itemName}][${newName}]`);

		if ( typeof(mod.itemName) === 'undefined' || mod.itemName.length < 1 )
		{
			throw `addItem: name must be specified!`;
		} else {
			//const rei = new RegExp(`^${mod.itemName}$`,'i');
			const rei = new RegExp(`^${newName}$`,'i');

			const idl = state.lists.findIndex(x => rel.test(x.name));
			if ( idl == -1 ) {
				throw `addItem: list ${mod.listName} not found!`;
			} else {
				const list = state.lists[idl];

				const idi = list.items.findIndex(x => rei.test(x.name));
        //console.error(`idi: ${idi}`);
				if ( idi == -1 ) {
					const item = {
						_id: '',
						name: mod.itemName.trim(),
						checked: false,
						created: mDate,
						modified: mDate,
						synced: 0,
						deleted: false
					};

					list.items.push(item);
					list.modified = mDate;
					state.lists.splice(idl,1,list);
				} else {
					const item = list.items[idi];
					if ( item.checked || item.deleted) {
						item.checked = false;
						item.deleted = false;
						item.modified = mDate;
						list.items.splice(idi,1,item);
						list.modified = mDate;
						state.lists.splice(idl, 1, list);
					} else {
						throw `addItem: ${mod.itemName} already exists!`;
					}
				}
			}
		}
	},


	setLastSync(state, ms): void {
		if ( ms != 0 && ms < state.lastSync ) {
			throw `setLastSync: Can't roll back from ${state.lastSync} to ${ms}!`;
		} else {
			state.lastSync = ms;
		}
	},


	// only called to commit objects returned from the server,
	commitList(state, newList: List): void {

    //console.log('commitList: %o', newList);
		checkListID(state.lists, newList);

		const rel = new RegExp(`^${newList.name}$`,'i');
		let idl = state.lists.findIndex(x => 
			(newList._id != '' && x._id == newList._id) || 
			rel.test(x.name));

    //console.log(`idl: ${idl}:[${newList.name}]`);
		if ( idl == -1 ) {
			if ( !newList.deleted ) {
				checkUniqueList(state.lists, newList);
				state.lists.push(newList);
			}
		} else {
			const list = state.lists[idl];
      //console.log('list: %o', list);

			if ( state.lastSync == 0 || list._id == '' ) {
				list._id = newList._id;
			} else if ( list._id != newList._id ) {
				//console.error(`commitList: ID has changed! [${list._id}] to [${newList._id}]`);
				//console.error(`assuming list was deleted from another device`);
				state.lists.splice(idl, 1);
				checkUniqueList(state.lists, newList);
				state.lists.push(newList);
				idl = state.lists.findIndex(x => x._id == newList._id);
				//throw `commitList: List ID mismatch! ${list._id} / ${newList._id}`;
			}

			newList.items.forEach( (i: ListItem) => {
        //console.log('item: %o',(i as ListItem));
				//checkItemID(list.items, i);
        const newName = (i as ListItem).name.replace(/[-[\]{}()*+?.,\\/^$|#\s]/g, "\\$&");
        //console.log(`newName: [${newName}]`)
				//const rei = new RegExp(`^${(i as ListItem).name}$`,'i');
				const rei = new RegExp(`^${newName}$`,'i');
        //console.log('searching %o', list)
				const idi = list.items.findIndex(x => 
					(i._id != '' && x._id == i._id) ||
					rei.test(x.name));
        //console.error(`idi: ${idi}`);
				if ( idi == -1 ) {
					// new item
          //console.log(`Warning: adding [${(i as ListItem)._id}]:[${(i as ListItem).name}] returned from server`)
					checkUniqueItem(list.items, i);
					list.items.push(i);
				} else {
					// update item
          //console.log('Update: ', JSON.stringify(list.items[idi]));
          //console.log('    to: ', JSON.stringify(i));

          //console.error(`Update: [${list.items[idi]._id}][${list.items[idi].name}] del: ${list.items[idi].deleted} to ${(i as ListItem).deleted}`)
					const item = list.items[idi];

					if ( state.lastSync == 0 || item._id == '' ) {
						item._id = i._id;
					} else if ( item._id != i._id ) {
						throw `commitList: Item ID mismatch! ${item._id} / ${i._id}`;
					}


					if ( i.deleted ) {
						//console.error(`delete item %o`,list.items[idi]);
						list.items.splice(idi,1);
						//console.log(`new items: %o`,list.items);
					} else {
						//console.error(`modify item %o`,list.items[idi]);
						if ( i.modified >= item.modified ) {
							for ( const [k,v] of Object.entries(i) ) {
								if ( modKeys.includes(k) ) {
									item[k] = v;
								}
							}
						} 

						list.items.splice(idi, 1, item);
					}
				}
			});

			if ( newList.modified >= list.modified ) {
				for ( const [k,v] of Object.entries(newList) ) {
					if ( modKeys.includes(k) ) {
						list[k] = v;
					}
				}
			} 
		
			if ( newList.deleted ) { // deletion always takes precedence!
				state.lists.splice(idl, 1);
			} else {
				state.lists.splice(idl, 1, list);
			}
		}
	},
}



const actions = {

	deleteList(context, mod: { listId: string, listName: string }) {
		return new Promise(function(resolve, reject) {
			try {
				context.commit('deleteList', mod);
				resolve('success');
			} catch (e) {
				reject(e);
			}
		});
	},


	deleteItem(context, mod: { listName: string, itemId: string, itemName: string} ) {
		return new Promise( (resolve, reject) => {
			try {
				context.commit('deleteItem', mod);
				resolve('success');
			} catch (e) {
				reject(e);
			}
		});
	},


	renameList(context, mod: { oldName: string, newName: string }) {
		return new Promise( (resolve, reject) => {
			try {
				context.commit('renameList', mod);
				resolve('success');
			} catch (e) {
				reject(e);
			}
		});
	},


	renameItem(context, mod: {listName: string, oldName: string, newName: string }) {
		return new Promise( (resolve, reject) => {
			try {
				context.commit('renameItem', mod);
				resolve( 'success' );
			} catch (e) {
				reject(e);
			}
		});
	},


	checkList(context, name: string) {
		return new Promise( (resolve, reject) => {
			try {
				context.commit('checkList', name);
				resolve('success');
			} catch (e) {
				reject(e);
			}
		});
	},


	checkItem(context, mod: { listName: string, itemName: string }) {
		return new Promise( (resolve, reject) => {
			try {
				context.commit('checkItem', mod);
				resolve( 'success' );
			} catch (e) {
				reject(e);
			}
		});
	},


	addList(context, name: string) {
		return new Promise(function(resolve, reject) {
			try {
				context.commit('addList', name);
				resolve('success');
			} catch (e) {
				reject(e);
			}
		});
	},

		
	addItem(context, mod: { listName: string, itemName: string }) {
		return new Promise( (resolve, reject) => {
			try {
				context.commit('addItem', mod);
				resolve('success');
			} catch (e) {
				reject(e);
			}
		});
	},


	commitList(context, list: List) {
		return new Promise( (resolve, reject) => {
			try {
				context.commit('commitList', list);
				resolve('success');
			} catch (e) {
				reject(e);
			}
		});
	},


	setLastSync(context, ms: number) {
		return new Promise( (resolve, reject) => {
			try {
				context.commit('setLastSync', ms);
				resolve('sucess');
			} catch(e) {
				reject(e);
			}
		});
	},
}

export	default {
	namespaced: false,
	state,
	getters,
	mutations,
	actions
}

