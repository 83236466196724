import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'

import { initializeApp } from 'firebase/app'
import * as firebaseui from 'firebaseui'

import { 
	getAuth, 
	onAuthStateChanged,
	sendEmailVerification,
	setPersistence,
	inMemoryPersistence,
	browserLocalPersistence,
	indexedDBLocalPersistence,
} from 'firebase/auth'

import firebaseConfig from '@/.firebase.json';

initializeApp(firebaseConfig);

const auth = getAuth();
//setPersistence(auth, inMemoryPersistence);
setPersistence(auth, browserLocalPersistence);

onAuthStateChanged(auth, (user) => {
	if ( user != null ) {
		//console.log(`logged in: %o`,user);
		if ( user.emailVerified ) {
			store.dispatch('login', user);
		} else {
			sendEmailVerification(user);
		}
	} else { 
		//console.log(`not logged in`);
		store.dispatch('logout');
	}
});


createApp(App).use(store).mount('#app')
