import axios from 'axios'

import { List, ListItem } from '@/types'

axios.defaults.withCredentials = true;
//axios.defaults.baseURL = 'https://lists.mindtunnel.com/list-server';
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

const OFFLINE = 'Error: Network Error';
const DUPE = 211;

const errorHandler = (err) => {
	if ( err.response ) {
		console.error(`err.response: %o`, err.response);
		throw (err.response.data);
	} else if ( err.request ) {
		console.error(`err.request: %o`, err.request);
		throw (err.request.toString());
	} else {
		console.error(`err: %o`, err);
		throw (err.message.toString());
	}
}

export default {

	setDevice(id) {
		axios.defaults.headers.common['X-Auth-Device'] = id;
	},


	async login(token) {
		try {
			const resp = await axios.post('/login', token);
			//console.log(`svr login resp: %o`, resp);
			return resp.data;
		} catch (err) {
			console.error(`svr login error: %o`, err.response.data);
			errorHandler(err);
			//return err.response.data;
		}
	},

	async logout() {
		try {
			await axios.post('/logout');
		} catch (err) {
			errorHandler(err);
		}
	},

	async getMembers( list ) {
		try {
			const response = await axios.post('/getMembers', list);
			return response.data;
		} catch(err) {
			errorHandler(err);
		}
	},

	async invite( data ) {
		try {
			const response = await axios.post('/invite', data);
			return response.data;
		} catch(err) {
			errorHandler(err);
		}
	},

	async accept(action) {
		try {
			const response = await axios.post('/accept', action );
			return response.data;
		} catch(err) {
			errorHandler(err);
		}
	},

	async sync(lists) {
		try {
			const response = await axios.post('/sync', lists);
			return response.data;
		} catch(err) {
			errorHandler(err);
		}
	},
}

