

import { defineProps } from 'vue'
import { Options, Vue } from 'vue-class-component';
import { useStore, mapGetters, mapActions } from 'vuex';

//import { initializeApp } from 'firebase/app'

import firebaseConfig from '@/.firebase.json';
import { 
	getAuth, 
	signOut
} from 'firebase/auth'

import Swal from 'sweetalert2'

//import keys from '@/.google.keys.json'

import svr from '@/services/lists'


@Options( {
	components: {},

	computed: {
		...mapGetters( [ 
			'device',
			'loggedIn', 
			'user', 
			'onLine'
		] ),
	},
	methods: {
		//...mapActions([ ])
	},
	emits: [ 'error', 'logout' ]
})


export default class Panel extends Vue {

	// data
	//private isOnline = navigator.onLine
	//public store = useStore();

	// methods

	public showAbout(): void {
		const aboutText = `
		Copyright (C) Wayne Vosberg - All Rights Reserved<br/>
		Unauthorized copying of this file, via any medium is 
		strictly prohibited.<br/>
		Proprietary and confidential<br/>
		Written by Wayne Vosberg &lt;wayne@mindtunnel.com&gt;, 2020, 2021<br/>
		`

		Swal.fire({ 
			width: '90%',
			title: 'mindtunnel lists',
			icon: 'info', 
			html: aboutText, 
			confirmButtonText: 'Close',
			confirmButtonColor: '#416c94',
			showCancelButton: false,
			reverseButtons: false 
		});
	}


	public confirmLogout(): void {
		Swal.fire({ 
			title: 'mindtunnel lists',
			icon: 'question', 
			html: 'Do you really want to logout?', 
			confirmButtonText: 'Logout',
			confirmButtonColor: '#416c94',
			showCancelButton: true,
			reverseButtons: true 
		}).then( (r) => {
			if ( r.isConfirmed ) {
				this.doLogout();
			}
		});
	}

	public async doLogout(): Promise<void> {
		try {
			//console.log(`do logout`);

			const auth = getAuth();
			await signOut(auth);
			//console.log(`signOut success`);
		} catch(e) {
			console.error(`logout error: ${e.toString()})`);
			this.$emit('error', `logout error: ${e.toString()}`);
		}
	}
}
