import { User, List } from '@/types';

import svr from '@/services/lists'

const svrLogout = async () => {
	try {
		await svr.logout();
		return true;
	} catch (e) {
		throw `svrLoout: ${e.toString()}`;
	}
}


const svrLogin = async (idToken)  => {
	try {
		await svr.login({ idToken: idToken });
		return true;
	} catch (e) {
		console.error(`svrLogin: ${e.toString()}`);
		return false;
	}
}

const state = () => ({
	loggedIn: false,
	onLine: false,
	user: {} as User
})

const getters = {
	onLine(state) {
		return state.onLine;
	},
	loggedIn(state) {
		return state.loggedIn;
	},
	user(state): User {
		return state.user;
	},
	email(state): string {
		return state.user.email.trim().toLowerCase();
	},
	gid(state): string {
		return state.user.id;
	},
}

const mutations = {
	onLine(state) {
		state.onLine = true;
	},
	offLine(state) {
		state.onLine = false;
	},
	login (state, u: User ) {
		state.user = u;
		state.loggedIn = true;
	},
	logout (state) {
		state.loggedIn = false;
	},
}

const actions = {

	login(context, user) {
		//console.log(`login user: %o`, user );

		return new Promise( (resolve, reject) => {
			try {

				// reset data if this is a new user
				if ( user.email != context.state.user.email ) {
					context.rootState.liststore.lastSync = 0;
					context.rootState.liststore.lists = [] as List[];
				}

				context.commit( 'login', {
					name: user.displayName,
					email: user.email,
					photoURL: user.photoURL,
					id: user.uid,
				});

				//console.log(`user: %o`,user);
				svr.login({ idToken: user.accessToken }).then( (r) => {
					//console.log(`svr.login returned: %o`,r);
					context.commit('onLine');
				}).catch( (e) => {
					console.error(`svr.login error: %o`,e);
					context.commit('offLine');
				});

				resolve('success');

			} catch (e) {
				reject(e);
			}
		});
	},

	logout(context) {
		return new Promise( (resolve, reject) => {
			try {
				context.commit('logout' );
				context.commit('setLastSync', 0);
				context.commit('offLine');
				//if ( svrLogout() ) {
				//}
				resolve('success');
			} catch(e) {
				reject(e);
			}
		});
	}
}

export default {
	namespaced: false,
	state,
	getters,
	mutations,
	actions
}
