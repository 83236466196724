
import { Options, Vue } from 'vue-class-component';
import { ref } from 'vue';
import { SortKeys } from '@/types';
import { mapGetters } from 'vuex';

@Options({
	computed: {
		...mapGetters( ['onLine'] ),
	},
	props: [ 'keys', 'choices', 'cbLabel' ],
	emits: [ 'update:keys', 'sync' ]
})

export default class SortControl extends Vue {

	setup(props) {
		const keys: SortKeys = ref(props.keys) as any;
		return { keys };
	}

	public changeOrder(keys: SortKeys): void {
		keys.order = ! keys.order;
		this.$emit('update:keys', {...keys});
	}

	public changeKey(keys: SortKeys, values): void {
		const nkey = keys.key >= values.length-1 ?  0 : keys.key+1;
		keys.key = nkey;
		this.$emit('update:keys', {...keys});
	}

	public changeCheck(keys): void {
		keys.checked = ! keys.checked;
		this.$emit('update:keys', {...keys});
	}

	public getKey(keys,values): string {
		return values[keys.key].replace(/^\w/, c => c.toUpperCase());
	}
}
