
import { Options, Vue } from 'vue-class-component';

import Swal from 'sweetalert2'

import { List, ListItem } from '@/types';

@Options({
	props: [ 'item' ],
	components: {
	}
})

export default class Items extends Vue {

	private editName = false
	private newName = ''

	public clearEdit(): void {
		this.editName = false;
	}

	public renameItem(thisItem: string): void {
		if ( this.editName ) {
			this.editName = false;
			if ( this.newName.length > 0 && thisItem != this.newName ) {
				this.$emit('rename', { oldName: thisItem, newName: this.newName } );
			}
		} else {
			this.editName = true;
			this.newName = thisItem;
		}
	}

	public clearAction(name: void): void {
		const q = `Really delete <b>${name}</b>?`;
		Swal.fire({ 
			title: 'mindtunnel lists',
			icon: 'warning', 
			html: q, 
			confirmButtonText: 'Delete',
			confirmButtonColor: '#416c94',
			showCancelButton: true,
			reverseButtons: true 
		}).then( (r) => {
			if ( r.isConfirmed ) {
				this.$emit('delete');
			}
		});
	}

	// computed
	get actionIcon(): string {
		return this.editName ? 'save_alt' : 'edit';
	}
}
