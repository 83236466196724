

import { Options, Vue } from 'vue-class-component';

@Options({
	props: [ 'addType' ],
	emits: [ 'addThis' ]
})

export default class AddDialog extends Vue {

	private itemName = ""

	public addThis(): void {
		if ( this.itemName.length > 0 ) {
			this.$emit('addThis', this.itemName);
			this.itemName = "";
		}
	}

	public clearNew(): void {
		this.itemName = "";
	}
}
