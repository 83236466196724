
import { Options, Vue } from 'vue-class-component';
import { useStore, mapGetters, mapActions } from 'vuex';

import { List } from '@/types'

import { getAuth } from 'firebase/auth';

import svr from '../services/lists';

import AddDialog from './AddDialog.vue'
import SortControl from './SortControl.vue'
import ListsDetails from './ListsDetails.vue'

@Options({
	components: {
		ListsDetails,
		SortControl,
		AddDialog
	},

	computed: {
		...mapGetters( ['email', 'onLine'] ),
	},

	methods: {
		...mapActions([ 'checkList' ])
	},

	props: [ 'msg', 'SortKeys' ],
	emits: [ 'update:keys', 'sync', 'select', 'error' ]
})

export default class Lists extends Vue {

	public store = useStore();
	public auth = getAuth();

	private listKeys = { key: 0, order: true, checked: false }
	private listKeyValues = [ 'name', 'created', 'modified' ]

	public async commitShares(list, newShares): Promise<void> {
		try {
			const mDate = Date.now();
			const r = await svr.invite( { 
				idToken: await this.getIdToken(),
				list: list, 
				members: newShares 
			} );
			//console.log(`invite returned: %o`,r);
			this.$emit('sync');
		} catch (e) {
			this.$emit('error', `commitShares: ${e.toString()}`);
		}
	}

	public selectList(l: List) {
		this.$emit('select', l);
	}

	public async addList(name: string): Promise<void> {
		try {
			await this.store.dispatch('addList', name);
			this.$emit('sync');
		} catch (e) {
			this.$emit('error', `addList: ${e.toString()}`);
		}
	}

	public async deleteList( listId: string, listName: string ): Promise<void> {
		try {
			await this.store.dispatch('deleteList', { listId: listId, listName: listName } );
			this.$emit('sync');
		} catch (e) {
			this.$emit('error', `deleteList: ${e.toString()}`);
		}
	}

	public async renameList(upd: 
		{ oldName: string, newName: string } ): Promise<void> {
		try {
			await this.store.dispatch( 'renameList', upd );
			this.$emit('sync');
		} catch (e) {
			this.$emit('error', `renameList: ${e.toString()}`);
		}
	}

	get activeLists(): Record<string, any> {
		return this.sortedLists(false);
	}
	get checkedLists(): Record<string, any> {
		return this.sortedLists(true);
	}

	public sortedLists(checked: boolean): Record<string,any> {
		const key = this.listKeyValues[this.listKeys.key];
		const ord = this.listKeys.order;

		const comp = (cmpKey, cmpOrd) => (a,b) => { 
			const varA = (typeof a[cmpKey] === 'string') ?	
				a[cmpKey].toUpperCase() : a[cmpKey];
			const varB = (typeof b[cmpKey] === 'string') ?	
				b[cmpKey].toUpperCase() : b[cmpKey];

			const comparison = varA > varB ? 1 : (varA < varB ? -1 : 0);

			return comparison * (cmpOrd? 1 : -1);
		}

		const hidden = () => (a) => 
			{ return a['deleted'] == false && a['checked'] == checked };
		
		const sorted = this.store.getters.lists.filter(hidden()).
			sort(comp(key, ord));

		return sorted;
	}

	public async getIdToken(): Promise<string> {
		let idToken = '';
		if ( this.auth.currentUser ) {
			idToken = await this.auth.currentUser.getIdToken(true);
		}
		return idToken;
	}

	mounted() {
		//this.$emit('sync');
	}
}
