import { createStore, createLogger } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { v4 as uuid } from 'uuid';
import svr from '@/services/lists';

//import createPersistedState from './plugins/persistence';

//import VuexPersist from 'vuex-persist';

//import { User } from '@/types';

import userstore from './modules/users';
import liststore from './modules/lists';

const debug = process.env.NODE_ENV !== 'production';

const plugins = [
	createPersistedState({
		fetchBeforeUse: true,
		key: 'lists',
		fecthBeforeUse: true,
		rehydrated: (l) => { 
			l.state.storageReady = true;
			l.dispatch('updateDevice');
		},
	})
	//createPersistedState({
	//	strictMode: false,
	//	modules: []
	//})
];

const devPlugins = [
	createLogger()
];

const prodPlugins = [];

export default createStore({
	strict: false,
  state: {
		used: '',
		storageReady: false,
		device: 'xxxx',
		//loggedIn: false,
		//user: {} as User,
  },
	getters: {
		device(state) {
			return state.device;
		},
		storageReady(state) {
			return state.storageReady;
		},
	},
  mutations: {
		updateDevice(state) {
			if ( state.device === 'xxxx' ) {
				state.device = uuid();
			}
		},
		initialize(state) {
			state.used = Date().toLocaleString();
			if ( ! state.storageReady ) { state.storageReady = true }
		},
  },
  actions: {
		updateDevice(context) {
			return new Promise( (resolve, reject) => {
				try { 
					context.commit('updateDevice');
					svr.setDevice(context.state.device);
				} catch (e) {
					reject(e);
				}
			});
		},

		initialize(context) {
			return new Promise( (resolve, reject) => {
				try { 
					context.commit('initialize');
					context.commit('updateDevice');
					resolve(context.state.used);
				} catch(e) {
					reject(e);
				}
			});
		},
  },

  modules: {
		userstore,
		liststore
  },

	plugins: debug 
		? [...plugins, ...devPlugins]
		: [...plugins, ...prodPlugins],
})
