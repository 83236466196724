

import { Options, Vue } from 'vue-class-component';

import { useStore, mapGetters, mapActions } from 'vuex';

import AddDialog from './AddDialog.vue';
import SortControl from './SortControl.vue';
import ItemsDetails from './ItemsDetails.vue';

import { List, ListItem } from '@/types';

@Options({

	props: [ 'list' ], 

	components: {
		AddDialog,
		SortControl,
		ItemsDetails
	},

	emits: [ 'error', 'sync', 'update:list' ]
})

export default class Items extends Vue {

	private store = useStore();
	private itemKeys = { key: 0, order: true, checked: false }
	private itemKeyValues = [ 'name', 'created', 'modified' ]

	private listName = "";

	public goBack(): void {
		this.$emit('update:list', '');
	} 

	public async addItem( newItem: string ): Promise<void> {
		try {
			await this.store.dispatch('addItem', 
				{ listName: this.listName, itemName: newItem } 
			);
			this.$emit('sync');
		} catch (e) {
			this.$emit('error', `addItem: ${e.toString()}`);
		}
	}


	// methods
	public async deleteItem( itemId: string, itemName: string ): Promise<void> {
		try {
			await this.store.dispatch( 'deleteItem', 
				{ listName: this.listName, itemId: itemId, itemName: itemName} 
			);
			this.$emit('sync');
		} catch (e) {
			this.$emit('error', `deleteItem: ${e.toString()}`);
		}
	}

	public async checkItem( item: string ): Promise<void> {
		try {
			await this.store.dispatch( 'checkItem', 
				{ listName: this.listName, itemName: item } 
			);
			this.$emit('sync');
		} catch (e) {
			this.$emit('error', `checkItem: ${e.toString()}`);
		}
	}

	public async renameItem( mod: 
		{ oldName: string, newName: string } ): Promise<void> {
		try {
			await this.store.dispatch( 'renameItem', { 
				listName: this.listName, 
				oldName: mod.oldName, 
				newName: mod.newName 
			});
			this.$emit('sync');
		} catch (e) {
			this.$emit('error', `renameItem: ${e.toString()}`);
		}
	}



	public myItems(checked: boolean): Record<string,any> {

		const listName = this.$props['list'];
		const key = this.itemKeyValues[this.itemKeys.key];
		const ord = this.itemKeys.order;

		const comp = (cmpKey, cmpOrd) => (a,b) => { 
			const varA = (typeof a[cmpKey] === 'string') ?  
				a[cmpKey].toUpperCase() : a[cmpKey];
			const varB = (typeof b[cmpKey] === 'string') 
				?  b[cmpKey].toUpperCase() : b[cmpKey];

			const comparison = varA > varB ? 
				1 : (varA < varB ? -1 : 0);

			return comparison * (cmpOrd? 1 : -1);
		}

		try {
		return this.store.getters.lists.find(a => a.name == this.listName)
			.items.filter(b => b.deleted == false && b.checked == checked)
			.sort(comp(key,ord));
		} catch (e) {
			console.error(`Error retrieving list [${listName}]: ${e.toString()}`);
			this.$emit('error',`Error retrieving list [${listName}]`)
			this.goBack();
			return [];
		}
	}

	get activeItems(): Record<string,any> {
		return this.myItems(false);
	}

	get completedItems(): Record<string,any> {
		return this.myItems(true);
	}

	created() {
		this.listName = this.$props['list'];
	}
}
